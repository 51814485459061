<script setup lang="ts">
import { pascalCase } from 'scule';
import { useSchemaOrg, defineBreadcrumb } from '@unhead/schema-org/vue';
import type { Schemas } from '#shopware';
import type { SharedOffcanvas } from '~/components/shared/SharedOffcanvas.vue';
import type { AhBreadcrumb } from '~/types/models/page';

defineOptions({
    name: 'PageResolver',
});

const nuxtApp = useNuxtApp();

const offcanvas = inject<SharedOffcanvas>('offcanvas') as SharedOffcanvas;
const { getPage, cleanedPath, pageBreadcrumbs } = usePage();
const { isLoggedIn } = useUser();
const { isHomePath } = useSeoPath();

const { customHomepagePath } = await getCustomHomepagePath();
const { setSeoPaths, setCanonicalUrl, i18nLocaleSlugs, pathToSeoUrl } = useLanguageSelect();

// load custom homepage if it is set in user data and if home page is called.
// works both for manual address change and in-app navigation but doesn't change homepage url.
let getPath = cleanedPath.value;
if (isHomePath.value && customHomepagePath) {
    getPath = customHomepagePath;
}

const { data: pageResult, error } = await useAsyncData(`page-${cleanedPath.value}`, () => getPage(getPath));

// redirect to url if seo_lookup page is found.
// runWithContext is needed to avoid "Nuxt instance unavailable" message.
// see: https://nuxt.com/docs/api/composables/use-nuxt-app#runwithcontext
if (pageResult.value?.apiAlias === 'seo_lookup' && pageResult.value?.url) {
    const path = pathToSeoUrl(pageResult.value?.url);
    await nuxtApp.runWithContext(() => navigateTo(path, { redirectCode: 301, replace: true }));
}

// @ts-ignore Property 'category' does not exist on type 'CmsPageResponse'
const isSecured = computed(() => !!pageResult.value?.category?.extensions?.attributes?.loginModal);
if (isSecured.value && !isLoggedIn.value) {
    offcanvas.open('AccountLoginForm', 'layout-login-offcanvas');
}

await setSeoPaths(pageResult.value?.seoUrls);

const setI18nParams = useSetI18nParams();
setI18nParams({
    ...i18nLocaleSlugs.value,
});
setCanonicalUrl(pageResult.value?.canonicalPathInfo);

// autoLanguageRedirect();

const { breadcrumbs, clearBreadcrumbs } = useBreadcrumbs(pageBreadcrumbs.value);

if (breadcrumbs.value?.length) {
    useSchemaOrg([
        defineBreadcrumb({
            itemListElement: (breadcrumbs.value as AhBreadcrumb[])?.map(({ name, seoPath }) => {
                let path = seoPath || undefined;
                if (path && !path.startsWith('/')) {
                    path = removeTrailingSlash('/' + path);
                }

                return {
                    name,
                    item: path,
                };
            }),
        }),
    ]);
}

onBeforeRouteLeave(() => {
    clearBreadcrumbs();
});

const { routeName } = useNavigationContext(
    ref({
        routeName: pageResult.value?.resourceType,
        foreignKey: pageResult.value?.resourceIdentifier,
    }) as Ref<Schemas['SeoUrl']>,
);

const componentName = routeName.value ?? '';
const componentNameToResolve = pascalCase(componentName);
const cmsPageView = componentNameToResolve ? resolveComponent(componentNameToResolve) : undefined;

if (error.value) {
    showError(error.value);
} else if (isSecured.value && !isLoggedIn.value) {
    showError({ statusCode: 401 });
}
</script>

<template>
    <Component
        :is="cmsPageView"
        v-if="cmsPageView && cmsPageView !== componentNameToResolve"
        :key="pageResult?.cmsPage?.id"
    />
    <div v-else-if="cmsPageView && cmsPageView === componentNameToResolve">
        Problem resolving component: {{ componentName }}
    </div>
    <div v-else>Loading...</div>
</template>
